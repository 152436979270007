import { useNavigate } from "react-router-dom";

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => ( images[item.replace('./', '')] = r(item) ));
    return images;
}
const images = importAll(require.context('../img/', false, /\.(png|jpe?g|svg)$/));

const Ad = (props) => {
    const navigate = useNavigate();
    const {ad} = props;
    let promoMode = <p className="ad-card-price">od {ad.price} zł</p>;

    if (ad.promotionPrice !== null) {
        // promoMode = <p className="ad-card-price"><span className="ad-card-price-stroke">od {ad.price} zł</span> <span className="ad-card-price-promo">od {ad.promotionPrice} zł</span></p>;
        promoMode = <p className="ad-card-price">od {ad.price} zł</p>;
    };

    return(
        <div className="col-lg-4 ads-card-container">
            <div className="ad-card-inner" onClick={()=>{navigate(ad.url)}}>
                <div className="ad-cart-img-part">
                    <img src={"https://test.cms.soft99.eu" + ad.image.url} alt=""/>
                    <p className="ad-card-title-tag">{ad.title}</p>
                </div>
                <div className="ad-card-text-part">
                    <div className="ad-card-icon-part">
                        <img src={images[ad.icon+'.png']} alt=""></img>
                    </div>
                    <div className="ad-card-subtitle-part">
                        <p className="ad-card-subtitle">{ad.subtitle}</p>
                        {promoMode}
                        {/* <p className="ad-card-price">od {ad.price} zł</p> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ad;