import {React, useEffect, useState, useRef} from "react";
import Navbar from "./Navbar";
import Package from "./Package";
import Footer from "./Footer";
import { Parallax } from 'react-parallax';
import InnerModal from "./InnerModal";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import plBgimg from "../img/pl-bgimg.png";
import plimg from "../img/pl-img.png";


const Loyalty = (props) => {
    const myRef = useRef(null)
  const {service, services} = props;
  let pricePart = <span>od x zł</span>;
  const executeScroll = () => myRef.current.scrollIntoView();
  let resBut = <button className="nav-res-service" onClick={executeScroll}>Zarejestruj się</button>;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, []);

  function IframeLoad() {
    console.log('iframe load');
    let myiFrame = document.getElementById("myiFrame");
    console.log(myiFrame);
  }

  const [open, setOpen] = useState(false);
  const baseImgUrl = "https://test.cms.soft99.eu";
  const parallaxBgImg = plBgimg;
  let mediaBox = 
      <div className="service-media-part">
        <div className="service-media-part-single">
          <img src={plimg} alt=""></img>
        </div>
      </div>

  return(
        <section>
          <Modal open={open} onClose={() => setOpen(false)} center>
            <InnerModal setOpen={setOpen} service={service && service}/>
          </Modal>
          <header>
            <Navbar services={services} service={service} />
          </header>
          <Parallax bgImage={parallaxBgImg} strength={500} bgClassName="parallax-bg-services">
          <div className="service-top-hero">
            <div className="service-top-hero-tag">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-top-hero-bottom-part">
              <div className="service-top-hero-title">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <h1>Program lojalnościowy</h1>
                      {/* <p className="ybadge2">Wash Mist GRATIS!</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="service-top-hero-black-bottom">
                <div className="container dontshowmeonmobile">
                  <div className="row">
                    <div className="col-lg-6 col-sm-8">
                      <p className="service-top-hero-black-bottom-subtitle">Korzystasz z usług naszej myjni, detailingu lub serwisu? Zbieraj punkty i wymieniaj je na nagrody.</p>
                      <p className="service-top-hero-black-bottom-price"></p>
                    </div>
                    <div className="col-lg-6 col-sm-4">
                      {resBut}
                    </div>
                  </div>
                </div>
                <div className="container showmeonlyonmobile">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="service-top-mob-left">
                        <p className="service-top-hero-black-bottom-subtitle">Korzystasz z usług naszej myjni, detailingu lub serwisu? Zbieraj punkty i wymieniaj je na nagrody.</p>
                        <p className="service-top-hero-black-bottom-price"></p>
                      </div>
                      <div className="service-top-mob-right">
                        {resBut}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </Parallax>
          <div className="service-main-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                    <p className="service-desc-par"><b>Wystarczy:</b></p>
                    <ol className="service-desc-par">
                       <li><p className="service-desc-par">Założyć konto podając adres e-mail i hasło;</p></li>
                       <li><p className="service-desc-par">Po zalogowaniu, wypełnić dane: imie, nazwisko, nr tel;</p></li>
                       <li><p className="service-desc-par">Punkty są przyznawane za usługi myjni i detailingu oraz za usługi serwisowe;</p></li>
                       <li><p className="service-desc-par">Punkty zdobywasz wg wzoru: 1 zł wydane na usługi = 1 pkt w programie;</p></li>
                       <li><p className="service-desc-par">Dodatkowe punkty możesz zdobyć za polecenie programu znajomym. Każdy zarejestrowany znajomy = 50 pkt;</p></li>
                       <li><p className="service-desc-par">Zbieraj punkty i wymieniaj na nagrody. Do zdobycia produkty Soft99 oraz usługi w Soft99 Garage.</p></li>
                    </ol>
                </div>
                <div className="col-lg-5">
                  {mediaBox}
                </div>              
              </div>
              <div className="row services-packages-row plloginbox">
                <p className="lsbut lsbutreg"><a href="https://app.loyaltystarter.io/member/ffc201e8-38c7-40d7-851c-d505287e72b9/sign-up" target="_blank">Zarejestruj się</a></p>
                <p className="lsmidle">lub</p>
                <p className="lsbut lsbutlog"><a href="https://app.loyaltystarter.io/member/ffc201e8-38c7-40d7-851c-d505287e72b9/login" target="_blank">Zaloguj się</a></p>
              </div>
            </div>
          </div>
          <Footer />
        </section>
    )
};

export default Loyalty;