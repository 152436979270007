import {React, useState, useEffect} from "react";

const InnerModalAutoZS = () => {

    return(
        <div>
            <p className="opopnytextreg">Skontaktuj się z nami już dziś pod numerem:<br /> <b>+48 607 285 285</b><br />i umów się na dogodny termin sprawdzenia samochodu.</p>
        </div>
        
    ) 
}

export default InnerModalAutoZS;