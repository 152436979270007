import {React, useState, useEffect} from "react";

const InnerModalOpony = () => {

    return(
        <div>
            <p className="opopnytextreg"><b>Szczyt sezonu na zmianę opon jest właśnie teraz!</b><br /><br />W tym gorącym okresie prosimy o telefoniczną rezerwację wizyt.<br />Zadzwoń pod numer <b>+48 607 285 285</b>, by umówić dogodny termin.</p>
        </div>
        
    )
}

export default InnerModalOpony;