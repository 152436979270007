import {React, useState} from "react";
import { useNavigate } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu';
import { Modal } from 'react-responsive-modal';
import logoMain from "../img/logo_garage.svg";
import InnerModal from "./InnerModal";
import InnerModalSzkolenie from "./InnerModalSzkolenie";
import InnerModalOpony from "./InnerModalOpony";
import Cennik from "./Cennik";
import menHam from "../img/menham.svg";
import 'react-responsive-modal/styles.css';
import InnerModalAutoZS from "./InnerModalAutoZS";

const Navbar = (props) => {
    let szkoltest = false;
    let butname = 'Umów się na wizytę';
    if (props.szkoleniejest === true) {
        szkoltest = true;
        butname = 'Zapisz się na szkolenie';
    };
    // autozakupjest
    let autotest = false;
    if (props.autozakupjest === true) {
        autotest = true;
        butname = 'Umów się';
    };
    const [open, setOpen] = useState(false);
    const [openSecond, setOpenSecond] = useState(false);
    const [openO, setOpenO] = useState(false);
    const navigate = useNavigate();
    const {services, service} = props;
    const [openAutoZS, setOpenAutoZS] = useState(false);
    const handleMapServices = () => {
        let temp = services;
        const listedServices = temp.sort((a, b) => a.title > b.title ? 1 : -1).map((service)=>{
            if (service.id != 2 && service.id != 7) {
            return <a key={service.id} href={'/' + service.garage_category.path +"/"+ service.path}>
                 <p>{service.title}</p>
            </a>
            };
        });
        return listedServices;
    }

    const handleMapMobileServices = () => {
        let temp = services;
        const listedServices = temp.sort((a, b) => a.title > b.title ? 1 : -1).map((service)=>{
            return <a key={service.id} id={service.title} className="menu-item" href={'/' + service.garage_category.path +"/"+ service.path}>{service.title}</a>
        });
        return listedServices;
    }

    return(
        <nav className="nav">
        <Menu right>
            <a id="promo" className="menu-item" href={'/promocje'}>Promocje</a>
            <a id="cennik" className="menu-item menu-item-bold" onClick={() => setOpenSecond(true)}>Cennik</a>
            {services && handleMapMobileServices()}
            <a key="" id="mps" className="menu-item" href={'/samochod'}>Sprzedaż lub zakup auta</a>
            <a key="" id="mpj" className="menu-item" href={'/szkolenie/main'}>Szkolenia</a>
            <a key="" id="mpl" className="menu-item" href={'/program-lojalnosciowy'}>Program Lojalnościowy</a>
            <a id="kontakt" className="menu-item" href={'/kontakt'}>Kontakt</a>
        </Menu>
            <Modal open={openSecond} onClose={() => setOpenSecond(false)} center>
                <Cennik />
            </Modal>
            {szkoltest === false && 
            <Modal open={open} onClose={() => setOpen(false)} center>
                <InnerModal setOpen={setOpen} service={service && service}/>
            </Modal>
            }
            {(service && service.title == 'Felgi i opony') &&
                <Modal open={openO} onClose={() => setOpenO(false)} center>
                    <InnerModalOpony setOpenO={setOpenO}/>
                </Modal>
            }
            {szkoltest === true && 
                <Modal open={open} onClose={() => setOpen(false)} center>
                    <InnerModalSzkolenie dataszko={'05.11.2022'}/>
                </Modal>
            }
            {autotest === true && 
                <Modal open={openAutoZS} onClose={() => setOpenAutoZS(false)} center>
                    <InnerModalAutoZS setOpenAutoZS={setOpenAutoZS}/>
                </Modal>
            }
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-sm-2">
                        <img className="logo-nav" onClick={()=>{navigate('/')}} src={ logoMain } alt="logo-main"/>
                    </div>
                    <div className="col-7 col-sm-7 hide-mobile">
                        <a className={`footer-active-link topphonefix lurohidemoonmobile ${butname === 'Zapisz się na szkolenie' ? 'nav-res-margin' : ''}`}href="tel:+48607285285">+48 607 285 285</a>
                    </div>
                    <div className="col-2 col-sm-2">
                        {(service && service.title == 'Felgi i opony' && !autotest) &&
                            <button className="nav-res" onClick={() => {setOpenO(true)}}>{butname}</button>
                        }
                        {(service && service.showReservationButton || !service && !autotest) && 
                        <button className={`nav-res ${butname == 'Zapisz się na szkolenie' ? 'nav-res-font' : ''}`} onClick={() => {setOpen(true)}}>{butname}</button>
                        }
                        {autotest === true &&
                            <button className="nav-res" onClick={() => setOpenAutoZS(true)}>Umów się</button>
                        }
                    </div>
                    <div className="col-1 col-sm-1">
                        <div className="btn-group dontshowmeonmobile">
                            <div className="dropdown-toggle main-menu-nav-tog" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src={menHam} alt=""/>
                            </div>
                            <ul className="dropdown-menu dropdown-menu-end main-menu-nav-drop">
                                <a id="promo" className="menu-item" href={'/promocje'}>Promocje</a>
                                <a id="cennik" className="menu-item menu-item-bold" onClick={() => setOpenSecond(true)}>Cennik</a>
                                {services && handleMapServices()}
                                <a key="" id="mps" className="menu-item" href={'/samochod'}>Sprzedaż lub zakup auta</a>
                                <a key="" id="mpj" className="menu-item" href={'/szkolenie/main'}>Szkolenia</a>
                                <a key="" id="mpl" className="menu-item" href={'/program-lojalnosciowy'}>Program Lojalnościowy</a>
                                <a id="kontakt" className="menu-item" href={'/kontakt'}>Kontakt</a>
                                <a id="regulamin" className="menu-item" target="_blank" href={'https://soft99.eu/reg/regulamin_wierzbiecice.pdf'}>Regulamin</a>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;