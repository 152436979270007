import {React, useEffect, useState, useRef} from "react";
import Navbar from "./Navbar";
import Package from "./Package";
import Footer from "./Footer";
import { Parallax } from 'react-parallax';
import InnerModal from "./InnerModal";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import plBgimg from "../img/nbn.jpg";
// import plBgimg from "../img/autozsban.png";
import plimg from "../img/pl-img.png";
import imgkupujedesktop from "../img/kupuje_desktop.jpg";
import imgkupujemobile from "../img/kupuje_mobile.jpg";
import imgsprzedajedesktop from "../img/sprzedaje_desktop.jpg";
import imgsprzedajemobile from  "../img/sprzedaje_mobile.jpg";
import imgdesckupuje1 from "../img/img_desc_kup_1.jpg";
import imgdescksprzedaje1 from "../img/img_desc_sprzed_1.jpg";
import imgdescksprzedaje2 from "../img/img_desc_sprzed_2.jpg";
// autozs icons
import iclayers from "../img/autozsicons/layers.svg";
import iccar from "../img/autozsicons/car.svg";
import icinterior from "../img/autozsicons/interior.svg";
import { render } from "@testing-library/react";
import InnerModalAutoZS from "./InnerModalAutoZS.js";



const AutoZS = (props) => {

    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
      }
      
      const images = importAll(require.context('../img/autozsicons/', false, /\.(png|jpe?g|svg)$/));

    const myRef = useRef(null)
  const {service, services, active} = props;
  let pricePart = <span>od x zł</span>;
  const executeScroll = () => myRef.current.scrollIntoView();
  let resBut = <button className="nav-res-service" onClick={() => setOpenAutoZS(true)}>Umów się</button>
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, []);

  const handleClickKupuje = event => {
    window.location.href="/samochod/zakup";
  };

  const handleClickSprzedaje = event => {
    window.location.href="/samochod/sprzedaz";
  };

  const [open, setOpen] = useState(false);
  const [openAutoZS, setOpenAutoZS] = useState(false);
  const baseImgUrl = "https://test.cms.soft99.eu";
  const parallaxBgImg = plBgimg;
  let mediaBox;
  let descriptionMain = 
    <div className="row">
        <div className="col-lg-7">
            <p className="service-desc-par"><b>Sprzedajesz swój samochód i chcesz, by wyróżnił się spośród innych modeli? A może masz na oku auto i chcesz je dokładnie sprawdzić przed finalną decyzją? W Soft99 Garage sprawimy, że cały proces przebiegnie bezproblemowo!</b></p>
            <p className="service-desc-par">Dla sprzedających stworzyliśmy 3 kompleksowe pakiety usług, dzięki którym zadbasz o kondycję maszyny w najlepszy możliwy sposób. Drobiazgową diagnostykę, inspekcję i detailing, wraz z protokołem i sesją zdjeciową, doceni każdy kupujący.</p>
            <p className="service-desc-par">Jeśli to ty rozglądasz się za nowym samochodem, przyjedź podczas oględzin do nas! W naszej stacji SKP i w warsztacie weryfikujemy i prześwietlamy każdy drobiazg, abyś miał pewność, że nie kupujesz kota w worku!</p>
        </div>
        <div className="col-lg-5">
            {mediaBox}
        </div>              
    </div>

    let etapy;

if (active == 'zakup') {
    mediaBox = 
        <div className="service-media-part">
            <div className="service-media-part-single">
                <img src={imgdesckupuje1} alt=""></img>
            </div>
        </div>
    descriptionMain = 
        <div className="row">
            <div className="col-lg-7">
                <p className="service-desc-par">Jeśli to ty rozglądasz się za nowym samochodem, przyjedź podczas oględzin do nas! W naszej stacji SKP i w warsztacie weryfikujemy i prześwietlamy każdy drobiazg, abyś miał pewność, że nie kupujesz kota w worku!</p>
                <p className="service-desc-par"><b>Cena trzyetapowego pakietu "Inspekcja przedzakupowa"<br /><span className="pst">450zł</span> <span className="pr">360 zł</span></b></p>
            </div>
            <div className="col-lg-5">
                {mediaBox}
            </div>              
        </div>
    etapy = 
    <div className="row services-packages-row">
        <div className="col-lg-4">
            <div className="package-single">
                <div class="package-single-name"><p>Etap 1</p></div>
                <div className="package-single-features">
                    <div class="package-single-features-item">
                        <p>Inspekcja przeprowadzana przez doświadczonego detailera, który oceni stan karoserii oraz wnętrza</p>
                    </div>
                    <div class="package-single-features-item">
                        <img class="package-single-features-item-icon" alt="" src={images['layers.svg']} />
                        <p class="package-single-features-item-name">Pomiar grubości powłoki lakierniczej na wszystkich elementach</p>
                    </div>
                    <div class="package-single-features-item">
                        <img class="package-single-features-item-icon" alt="" src={images['car.svg']} />
                        <p class="package-single-features-item-name">Optyczna weryfikacja ogólnego stanu powłoki lakierniczej (rysy, wżery, otarcia)</p>
                    </div>
                    <div class="package-single-features-item">
                        <img class="package-single-features-item-icon" alt="" src={images['car.svg']} />
                        <p class="package-single-features-item-name">Optyczna weryfikacja ogólnego stanu karoserii (dekory, plastiki, zaślepki, wgniecenia, korozja, inne ubytki)</p>
                    </div>
                    <div class="package-single-features-item">
                        <img class="package-single-features-item-icon" alt="" src={images['interior.svg']} />
                        <p class="package-single-features-item-name">Weryfikacja stanu wnętrza (tkaniny, skóry, kompletność i sprawność wyposażenia)</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-lg-4">
            <div className="package-single">
                <div class="package-single-name"><p>Etap 2</p></div>
                <div className="package-single-features">
                    <div class="package-single-features-item">
                        <p>Mechaniczne sprawdzenie samochodu z wykorzystaniem dostępnych w warsztacie urządzeń</p>
                    </div>
                    <div class="package-single-features-item">
                        <img class="package-single-features-item-icon" alt="" src={images['service.svg']} />
                        <p class="package-single-features-item-name">Weryfikacja stanu technicznego pojazdu na ścieżce diagnostycznej SKP</p>
                    </div>
                    <div class="package-single-features-item">
                        <img class="package-single-features-item-icon" alt="" src={images['control.svg']} />
                        <p class="package-single-features-item-name">Kontrola podwozia</p>
                    </div>
                    <div class="package-single-features-item">
                        <img class="package-single-features-item-icon" alt="" src={images['tire.svg']} />
                        <p class="package-single-features-item-name">Weryfikacja stanu kół oraz ogumienia</p>
                    </div>
                    <div class="package-single-features-item">
                        <img class="package-single-features-item-icon" alt="" src={images['lights.svg']} />
                        <p class="package-single-features-item-name">Weryfikacja stanu oświetlenia</p>
                    </div>
                    <div class="package-single-features-item">
                        <img class="package-single-features-item-icon" alt="" src={images['calendar.svg']} />
                        <p class="package-single-features-item-name">Weryfikacja roku produkcji szyb i zgodności z rokiem produkcji pojazdu</p>
                    </div>
                    <div class="package-single-features-item">
                        <img class="package-single-features-item-icon" alt="" src={images['computer.svg']} />
                        <p class="package-single-features-item-name">Diagnostyka komputerowa - weryfikacja błędów oraz odczyt poprawności podstawowych parametrów </p>
                    </div>
                    <div class="package-single-features-item">
                        <img class="package-single-features-item-icon" alt="" src={images['laboratory.svg']} />
                        <p class="package-single-features-item-name">Analiza składu spalin</p>
                    </div>
                    <div class="package-single-features-item">
                        <img class="package-single-features-item-icon" alt="" src={images['engine.svg']} />
                        <p class="package-single-features-item-name">Wizualna kontrola komory silnika oraz stanu kluczowych elementów - szczelność oraz stan ogólny</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-lg-4">
            <div className="package-single">
                <div class="package-single-name"><p>Etap 3</p></div>
                <div className="package-single-features">
                    <div class="package-single-features-item">
                        <p>Weryfikacja formalna, która pozwoli ocenić rzeczywisty przebieg i zgodność dokumentów</p>
                    </div>
                    <div class="package-single-features-item">
                        <img class="package-single-features-item-icon" alt="" src={images['dokument.svg']} />
                        <p class="package-single-features-item-name">Zgodność formalna z dokumentami</p>
                    </div>
                    <div class="package-single-features-item">
                        <img class="package-single-features-item-icon" alt="" src={images['checkbox.svg']} />
                        <p class="package-single-features-item-name">Weryfikacja zgodności nr VIN z pojazdem</p>
                    </div>
                    <div class="package-single-features-item">
                        <img class="package-single-features-item-icon" alt="" src={images['speed.svg']} />
                        <p class="package-single-features-item-name">Odczyt przebiegu odnotowanego w bazie CEPIK</p>
                    </div>
                    <div class="package-single-features-item">
                        <img class="package-single-features-item-icon" alt="" src={images['thift.svg']} />
                        <p class="package-single-features-item-name">Sprawdzenie w bazie pojazdów skradzionych</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  } else if (active == 'sprzedaz') {
    mediaBox = 
        <div className="service-media-part">
            {/* <div className="service-media-part-single">
                <img src={imgdescksprzedaje1} alt=""></img>
            </div> */}
            <div className="service-media-part-single">
                <img src={imgdescksprzedaje2} alt=""></img>
            </div>
        </div>
    descriptionMain = 
        <div className="row">
            <div className="col-lg-7">
                <p className="service-desc-par">Dla sprzedających stworzyliśmy 3 kompleksowe pakiety usług, dzięki którym zadbasz o kondycję samochodu w najlepszy możliwy sposób. Każdy kupujący doceni:</p>
                <ul className="service-desc-par">
                    <li>drobiazgową diagnostykę</li>
                    <li>dokładną inspekcję i detailing</li>
                    <li>wydrukowany protokół wykonanych prac</li>
                    <li>profesjonalną sesję zdjęciową</li>
                </ul>
            </div>
            <div className="col-lg-5">
                {mediaBox}
            </div>              
        </div>
    etapy = 
        <div className="row services-packages-row">
            <div className="col-lg-4">
                <div className="package-single">
                    <div class="package-single-name"><p>Pakiet S</p></div>
                    <div className="package-single-features">

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['wash.svg']} />
                            <p class="package-single-features-item-name"><b>Detailingowe mycie karoserii</b></p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['tire.svg']} />
                            <p class="package-single-features-item-name">Czyszczenie felg oraz opon</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['gloss.svg']} />
                            <p class="package-single-features-item-name">Odżywienie opon oraz tworzyw zewnętrznych</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['protect.svg']} />
                            <p class="package-single-features-item-name">Zabezpieczenie lakieru syntetycznym Quick Detailerem</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['interior.svg']} />
                            <p class="package-single-features-item-name">Sprzątanie oraz odkurzanie wnętrza</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['plastics.svg']} />
                            <p class="package-single-features-item-name">Czyszczenie plastików</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['glass.svg']} />
                            <p class="package-single-features-item-name">Czyszczenie szyb wewnątrz</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['wind.svg']} />
                            <p class="package-single-features-item-name">Ozonowanie wnętrza</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['photo.svg']} />
                            <p class="package-single-features-item-name"><b>Sesja zdjęciowa - pakiet 10 profesjonalnych zdjęć w scenerii Soft99 Garage w formacie JPEG</b></p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['service.svg']} />
                            <p class="package-single-features-item-name">Weryfikacja stanu technicznego na ścieżce diagnostycznej SKP wraz z wydrukiem</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['fill.svg']} />
                            <p class="package-single-features-item-name">Weryfikacja i uzupełnienie poziomu płynów eksploatacyjnych (olej silnikowy, płyn chłodniczy, płyn hamulcowy, płyn do spryskiwaczy) *MATERIAŁY KLIENTA</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['extinguisher.svg']} />
                            <p class="package-single-features-item-name">Weryfikacja i uzupełnienie ewentualnych braków wyposażenia obowiązkowego (gaśnica, trójkąt ostrzegawczy)*</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['pressure.svg']} />
                            <p class="package-single-features-item-name">Kontrola i korekta ciśnienia w ogumieniu</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['lights.svg']} />
                            <p class="package-single-features-item-name">Kontrola oświetlenia i uzupełnienie braków*</p>
                        </div>

                    </div>
                    <div class="packge-single-aditional-info">
                        <div class="package-single-time"></div>
                        <div class="package-single-price"><p><span className="pst">650 zł</span> <span className="pr">325 zł</span></p></div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="package-single">
                    <div class="package-single-name"><p>Pakiet M</p></div>
                    <div className="package-single-features">

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['wash.svg']} />
                            <p class="package-single-features-item-name"><b>Detailingowe mycie karoserii</b></p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['tire.svg']} />
                            <p class="package-single-features-item-name">Czyszczenie felg oraz opon</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['gloss.svg']} />
                            <p class="package-single-features-item-name">Odżywienie opon oraz tworzyw zewnętrznych</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['drops.svg']} />
                            <p class="package-single-features-item-name">Dekontaminacja lakieru oraz szyb</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['wax.svg']} />
                            <p class="package-single-features-item-name"><b>Zabezpieczenie lakieru syntetycznym woskiem twardym</b></p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['glass.svg']} />
                            <p class="package-single-features-item-name">Zabezpieczenie szyb powłoką hydrofobową Glaco</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['interior.svg']} />
                            <p class="package-single-features-item-name">Sprzątanie oraz odkurzanie wnętrza</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['plastics.svg']} />
                            <p class="package-single-features-item-name">Czyszczenie plastików</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['glass.svg']} />
                            <p class="package-single-features-item-name">Czyszczenie szyb wewnątrz</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['dirt.svg']} />
                            <p class="package-single-features-item-name"><b>Usuwanie widocznych plam z tkanin / Odświeżenie tapicerki skórzanej</b></p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['wind.svg']} />
                            <p class="package-single-features-item-name">Ozonowanie wnętrza</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['photo.svg']} />
                            <p class="package-single-features-item-name"><b>Sesja zdjęciowa - pakiet 10 profesjonalnych zdjęć w scenerii Soft99 Garage w formacie JPEG</b></p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['service.svg']} />
                            <p class="package-single-features-item-name">Weryfikacja stanu technicznego na ścieżce diagnostycznej SKP wraz z wydrukiem</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['fill.svg']} />
                            <p class="package-single-features-item-name">Weryfikacja i uzupełnienie poziomu płynów eksploatacyjnych (olej silnikowy, płyn chłodniczy, płyn hamulcowy, płyn do spryskiwaczy) *MATERIAŁY KLIENTA</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['extinguisher.svg']} />
                            <p class="package-single-features-item-name">Weryfikacja i uzupełnienie ewentualnych braków wyposażenia obowiązkowego (gaśnica, trójkąt ostrzegawczy)*</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['pressure.svg']} />
                            <p class="package-single-features-item-name">Kontrola i korekta ciśnienia w ogumieniu</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['lights.svg']} />
                            <p class="package-single-features-item-name">Kontrola oświetlenia i uzupełnienie braków*</p>
                        </div>

                    </div>
                    <div class="packge-single-aditional-info">
                        <div class="package-single-time"></div>
                        <div class="package-single-price"><p><span className="pst">850 zł</span> <span className="pr">425 zł</span></p></div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="package-single">
                    <div class="package-single-mostpoppular"><p>Najdokładniejszy</p></div>
                    <div class="package-single-name"><p>Pakiet L</p></div>
                    <div className="package-single-features">

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['wash.svg']} />
                            <p class="package-single-features-item-name">Detailingowe mycie karoserii</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['tire.svg']} />
                            <p class="package-single-features-item-name">Czyszczenie felg oraz opon</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['gloss.svg']} />
                            <p class="package-single-features-item-name">Odżywienie opon oraz tworzyw zewnętrznych</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['drops.svg']} />
                            <p class="package-single-features-item-name">Dekontaminacja lakieru oraz szyb</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['correct.svg']} />
                            <p class="package-single-features-item-name"><b>Jednoetapowa korekta lakieru</b></p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['wax.svg']} />
                            <p class="package-single-features-item-name">Zabezpieczenie lakieru syntetycznym woskiem twardym</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['glass.svg']} />
                            <p class="package-single-features-item-name">Zabezpieczenie szyb powłoką hydrofobową Glaco </p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['interior.svg']} />
                            <p class="package-single-features-item-name">Sprzątanie oraz odkurzanie wnętrza</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['plastics.svg']} />
                            <p class="package-single-features-item-name">Czyszczenie plastików</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['glass.svg']} />
                            <p class="package-single-features-item-name">Czyszczenie szyb wewnątrz</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['washing.svg']} />
                            <p class="package-single-features-item-name">Pranie wykładzin, boczków oraz podsufitki</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['washing.svg']} />
                            <p class="package-single-features-item-name"><b>Pranie tkanin / Czyszczenie oraz impregnacja skór</b></p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['wind.svg']} />
                            <p class="package-single-features-item-name">Ozonowanie wnętrza</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['photo.svg']} />
                            <p class="package-single-features-item-name"><b>Sesja zdjęciowa - pakiet 15 profesjonalnych zdjęć w scenerii Soft99 Garage w formacie JPEG</b></p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['service.svg']} />
                            <p class="package-single-features-item-name">Weryfikacja stanu technicznego na ścieżce diagnostycznej SKP wraz z wydrukiem</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['fill.svg']} />
                            <p class="package-single-features-item-name">Weryfikacja i uzupełnienie poziomu płynów eksploatacyjnych (olej silnikowy, płyn chłodniczy, płyn hamulcowy, płyn do spryskiwaczy) *MATERIAŁY KLIENTA</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['extinguisher.svg']} />
                            <p class="package-single-features-item-name">Weryfikacja i uzupełnienie ewentualnych braków wyposażenia obowiązkowego (gaśnica, trójkąt ostrzegawczy)</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['pressure.svg']} />
                            <p class="package-single-features-item-name">Kontrola i korekta ciśnienia w ogumieniu</p>
                        </div>

                        <div class="package-single-features-item">
                            <img class="package-single-features-item-icon" alt="" src={images['lights.svg']} />
                            <p class="package-single-features-item-name">Kontrola oświetlenia i uzupełnienie braków</p>
                        </div>

                    </div>
                    <div class="packge-single-aditional-info">
                        
                        <div class="package-single-price lilsherfix"><p><span className="pst">od 2297 zł</span> <span className="pr">od 1150 zł*</span></p></div>
                        <br /><br />
                        <div className="lilsherfix2"><p>* cena ustalana indywidualnie po wstępnej inspekcji pojazdu</p></div>
                    </div>
                </div>
            </div>
        </div>
  };

  return(
        <section>
          <Modal open={open} onClose={() => setOpen(false)} center>
            <InnerModal setOpen={setOpen} service={service && service}/>
          </Modal>
          <Modal open={openAutoZS} onClose={() => setOpenAutoZS(false)} center>
            <InnerModalAutoZS setOpenAutoZS={setOpenAutoZS}/>
          </Modal>
          <header>
            <Navbar services={services} service={service} autozakupjest={true}/>
          </header>
          <Parallax bgImage={parallaxBgImg} strength={500} bgClassName="parallax-bg-services">
          <div className="service-top-hero">
            <div className="service-top-hero-bottom-part">
              <div className="service-top-hero-title">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <h1>Sprzedaż lub zakup auta</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service-top-hero-black-bottom">
                <div className="container dontshowmeonmobile">
                  <div className="row">
                    <div className="col-lg-6 col-sm-8">
    
                      <p className="service-top-hero-black-bottom-subtitle">Pomożemy Ci dobrze sprzedać lub mądrze kupić!</p>
                      <p className="service-top-hero-black-bottom-price"><span className="pst">Od 650zł</span> <span className="pr">od 325 zł</span></p>
                      
                    </div>
                    <div className="col-lg-6 col-sm-4">
                      {resBut}
                    </div>
                  </div>
                </div>
                <div className="container showmeonlyonmobile">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="service-top-mob-left">
                        <p className="service-top-hero-black-bottom-subtitle">Pomożemy Ci dobrze sprzedać lub mądrze kupić!</p>
                        <p className="service-top-hero-black-bottom-price"><span className="pst">Od 650zł</span> <span className="pr">od 325 zł</span></p>
                      </div>
                      <div className="service-top-mob-right">
                        {resBut}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </Parallax>
          <div>
          <section className="black-section homepage-welcome-screen homepage-welcome-screen-fix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 cat-box">
                            <div className="cat-carousel">
                                <div className="cat-carousel-cards row">
                                    {/* zakup desktop */}
                                    <div className={"cat-carousel-card-szkolenie col-lg-6 col-md-6 col-sm-6 " + (active == 'zakup' ? "activebox" : "")} onClick={handleClickKupuje}>
                                        <div className="cat-carousel-card-inner-szkolenie">
                                            <div className="cat-carousel-card-img-szkolenie">
                                                <p className="ybadge-szkolenie">-20%</p>
                                                <img src={imgkupujedesktop} alt="" />
                                            </div>
                                            <div className="cat-carousel-card-title-szkolenie">
                                                <p>Kupuję Auto</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* sprzedaż desktop */}
                                    <div className={"cat-carousel-card-szkolenie col-lg-6 col-md-6 col-sm-6 " + (active == 'sprzedaz' ? "activebox" : "")} onClick={handleClickSprzedaje}>
                                        <div className="cat-carousel-card-inner-szkolenie">
                                            <div className="cat-carousel-card-img-szkolenie">
                                                <p className="ybadge-szkolenie">-50%</p>
                                                <img src={imgsprzedajedesktop} alt="" />
                                            </div>
                                            <div className="cat-carousel-card-title-szkolenie">
                                                <p>Sprzedaję Auto</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 mob-cat-box">
                            <div className="cat-car-mob-wrapper row">
                                {/* zakup mobile */}
                                <div className={"mob-cat-carousel-card col " + (active == 'zakup' ? "activebox" : "")} onClick={handleClickKupuje}>
                                    <div className="mob-cat-carousel-card-inner-szkolenie">
                                        <div className="mob-cat-carousel-card-img">
                                            <img src={imgkupujemobile} alt="" />
                                        </div>
                                        <div className="mob-cat-carousel-card-title">
                                            <p>Kupuję Auto</p>
                                        </div>
                                    </div>
                                </div>
                                {/* sprzedaż mobile */}
                                <div className={"mob-cat-carousel-card col " + (active == 'sprzedaz' ? "activebox" : "")} onClick={handleClickSprzedaje}>
                                    <div className="mob-cat-carousel-card-inner-szkolenie">
                                        <div className="mob-cat-carousel-card-img">
                                            <img src={imgsprzedajemobile} alt="" />
                                        </div>
                                        <div className="mob-cat-carousel-card-title">
                                            <p>Sprzedaję Auto</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>


          <div className="service-main-content">
            <div className="container">
                {descriptionMain}
                {etapy}

            </div>
          </div>
          <Footer />
        </section>
    )
};

export default AutoZS;