const isAtFreeTimeSlot = (start, end, stationId, allEvents) =>{

    const matchingStationEventList = allEvents.filter(singleEvent=>{
      return singleEvent.calendar_station.id === stationId;
    });

    if(matchingStationEventList.length===0){
      return true;
    }
    else{
      for(let i = 0; i < matchingStationEventList.length; i++){
        
        let camparedEventStart = matchingStationEventList[i].start
        let camparedEventEnd = matchingStationEventList[i].end
        if(camparedEventStart<=start &&
          camparedEventEnd>start ||
          camparedEventStart<end &&
          camparedEventEnd>=end || 
          camparedEventStart>start &&
          camparedEventEnd<end){
            if([23,24,25].includes(stationId)){
              alert("Wygląda na to, że ktoś właśnie zarezerwował ten termin... Prosimy wybrać inną godzinę.");
            }
              return false;
          }
          else{
            if(i===matchingStationEventList.length-1){
              return true;
            }
          }
      }
    }
    
}
export default isAtFreeTimeSlot
