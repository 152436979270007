import {React, useState, useEffect} from "react";
import axios from 'axios';

const InnerModalSzkolenie = (props) => {
    let dataSzkolenia = props.dataszko;
    
    let mailName;
    let szkolenieName;
    let mailPhone
    let mailMail;
    let mailSurName;
    let mailSzkolenie = "25.02.2023";

    
    let [selectedValue, setSelected] = useState();
    if (selectedValue == "Japońskie mycie detailingowe") {
        mailSzkolenie = "02.12.2023";
      } else if (selectedValue == "Korekta lakieru od A do Z"){
        mailSzkolenie = "09.12.2023";
      } else if (selectedValue == "Japońskie LSP"){
        mailSzkolenie = "25.11.2023";
      } else if (selectedValue == "Pakiet szkoleń"){
        mailSzkolenie = "17.06.2023 + 01.07.2023 + 15.07.2023";
      };
    
    const [mailSend, setMailSend] = useState(false);
    const [dataToShow, setDataToShow] = useState(mailSzkolenie);
    const setNameToMail = (e) => {mailName = e.target.value;}
    const setSurNameToMail = (e) => {mailSurName = e.target.value;}
    const setSzkolenieToMail = (e) => {
        szkolenieName = e.target.value;
        
    }
    const setPhoneToMail = (e) => {mailPhone = e.target.value;}
    const setMailToMail = (e) => {mailMail = e.target.value;}
    const setSzkolenie = (e) => {
        setDataToShow(e.target.value);
        mailSzkolenie = e.target.value;
    }
    let input;
    if (selectedValue == "Japońskie mycie detailingowe") {
        input = <><input type="text" name="data" placeholder="Poznań | 2 grudnia 2023" className="innerinput" value="Poznań | 2 grudnia 2023" disabled /><br /></>
      } else if (selectedValue == "Korekta lakieru od A do Z"){
        input = <><input type="text" name="data" placeholder="Poznań | 9 grudnia 2023" className="innerinput" value="Poznań | 9 grudnia 2023" disabled /><br /></>
      } else if (selectedValue == "Japońskie LSP"){
        input = <><input type="text" name="data" placeholder="Poznań | 25 listopad 2023" className="innerinput" value="Poznań | 25 listopad 2023" disabled /><br /></>
      } else if (selectedValue == "Pakiet szkoleń"){
        input = <><input type="text" name="data" placeholder="Poznań | 17.06.2023 + 01.07.2023 + 15.07.2023" className="innerinput" value="Poznań | 17.06.2023 + 01.07.2023 + 15.07.2023" disabled /><br /></>
      } else {
        input = <><input type="text" name="data" placeholder="" className="innerinput" value="" disabled /><br /></>
      };

    const handleConfirm = (e, mailName, mailSurName, selectedValue, szkolenieName, mailPhone, mailMail, mailSzkolenie) => {
        let myForm = document.querySelector('#myformmaster');
        e.preventDefault();
        myForm.checkValidity();
        myForm.reportValidity();
        if (myForm.reportValidity()) {

            console.log(mailName);
            console.log(selectedValue);
            console.log(mailPhone);
            console.log(mailMail);
            console.log(mailSzkolenie);
            console.log(mailSurName);
            console.log('wysylam');
            axios.post('https://it.soft99.pl/szkolenia_mailing/index.php', {
                data: ''+ mailSzkolenie+'',
                name: ''+mailName+'',
                email: ''+mailMail+'',
                phone: ''+mailPhone+'',
                trainingName: ''+selectedValue+'',
                surname: ''+mailSurName+'',
              })
              .then(function (response) {
                console.log('response');
                setMailSend(true);
                console.log(response.data);
              })
              .catch(function (error) {
                console.log('error');
                console.log(error);
              });
        };
    }
    

    const Forma = () => (
            <div className="innerwraper">
                <p className="pop-intro-text">Zapisz się już dziś!</p>
                {/* <p className="pop-intro-text">Aktualnie nie odbywają się zapisy!</p> */}
                <form method="post" className="myform" action="" id="myformmaster">
                    <br />
                    <select name="szkolenietyp" className="innerinput" value={selectedValue} onChange={(e)=>{ setSelected(e.target.value); setSzkolenieToMail(e)}} required>
                        <option value="" disabled selected>Wybierz szkolenie</option>
                        <option value="Pakiet szkoleń" disabled>Pakiet szkoleń</option>
                        <option value="Japońskie LSP" disabled>Japońskie LSP</option>
                        <option value="Japońskie mycie detailingowe" disabled>Japońskie mycie detailingowe</option>
                        <option value="Korekta lakieru od A do Z" disabled>Korekta lakieru od A do Z</option>
                        <option value="Szkolenie indywidualne" disabled>Szkolenie indywidualne</option>
                        <option value="Staż" disabled>Staż</option>
                    </select>
                    {console.log(selectedValue)}
                    {input}
                    {/* <input type="text" name="data" placeholder="Poznań | 4 luty 2023" className="innerinput" value="Poznań | 4 luty 2023" disabled/><br /> */}
                    <input type="text" name="imie" placeholder="Imię" className="innerinput" onChange={(e)=>{setNameToMail(e)}} required/><br />
                    <input type="text" name="nazwisko" placeholder="Nazwisko" className="innerinput" onChange={(e)=>{setSurNameToMail(e)}} required/><br />
                    <input type="email" name="email" placeholder="Email" className="innerinput" onChange={(e)=>{setMailToMail(e)}} required/><br />
                    <input type="tel" name="tel" placeholder="Telefon (+48)" className="innerinput" onChange={(e)=>{setPhoneToMail(e)}} required/><br />
                    <input type="submit" value="Zapisz się" className="innersubmit" onClick={(e)=>{handleConfirm(e, mailName, mailSurName, selectedValue, szkolenieName, mailPhone, mailMail, mailSzkolenie)}} /><br />
                    <div className="checkwrapper1">
                        <input type="checkbox"  id="vehicle1" name="vehicle1" value="true" className="innercheck" required/>
                        <label htmlFor="vehicle1" className="innerchecklabel">Zgadzam się na przesyłanie informacji handlowych za pośrednictwem środków komunikacji elektronicznej lub na kontakt telefoniczny.</label>
                    </div>
                    <div className="checkwrapper2">
                        <input type="checkbox" id="vehicle2" name="vehicle2" value="true" className="innercheck" required/>
                        <label htmlFor="vehicle2" className="innerchecklabel">Potwierdzam zapoznanie się z informacją administratora danych. Administratorem danych jest Nowy Samochód S.A., ul. Zbyszka Cybulskiego 3, 00-725 Warszawa.</label>
                    </div>
                    <span className="output_message"></span>
                </form>
            </div>
      )

    const ThankYou = () => (
        <div className="innerwraper">
            <p className="pop-intro-text">Sprawdź swoją skrzynkę e-mail!</p>
            <p className="pop-intro-text">Na twój adres e-mail wysłaliśmy informacje dotyczące płatności.<br />Wykonaj płatność aby potwierdzić swoje uczestnictwo w szkoleniu.</p>
            <p className="pop-center-text"><span>{mailSzkolenie}</span><span></span></p>
        </div>
    )


    return(
        <div>
        { mailSend ? <ThankYou /> : <Forma /> }
        </div>
        
    )
}

export default InnerModalSzkolenie;