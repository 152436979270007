import phone from "../img/phone.png";
import mail from "../img/mail.png";
import fb from "../img/fb.png";
import is from "../img/is.png";
import yt from "../img/yt.png";
import ml from "../img/ml.png";
const Footer = () => {
    return(
        <>
        <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-lg-4">
                            <h4>Kontakt</h4>
                            <p className="footer-phone">
                                {/* <img className="footer-icon" src={phone} alt=""></img>  */}
                                <a className="footer-active-link" href="tel:+48607285285">+48 607 285 285</a>
                            </p>
                            <p className="footer-mail">
                                {/* <img className="footer-icon" src={mail} alt=""></img>  */}
                                <a className="footer-active-link" href="mailto:kontakt@soft99garage.pl">kontakt@soft99garage.pl</a>
                            </p>
                            <div className="socialFooter">
                                <a href="https://www.facebook.com/Soft99Garage" target="_blank" className="socialFooterItem"><img className="social-footer-icon" src={fb} alt=""></img></a>
                                <a href="https://www.instagram.com/soft99_garage_poznan/" target="_blank" className="socialFooterItem"><img className="social-footer-icon" src={is} alt=""></img></a>
                                {/* <a href="https://www.instagram.com/soft99_garage/" target="_blank" className="socialFooterItem"><img className="social-footer-icon" src={is} alt=""></img></a> */}
                                <a href="https://www.youtube.com/c/Soft99Europe" target="_blank" className="socialFooterItem"><img className="social-footer-icon" src={yt} alt=""></img></a>
                                <a href="mailto:kontakt@soft99garage.pl" className="socialFooterItem"><img className="social-footer-icon" src={ml} alt=""></img></a>
                            </div>
                            
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <h4>Adres</h4>
                            <p>ul. Wierzbięcice 2A</p>
                            <p>61-568, Poznań</p>
                        </div>                        
                        <div className="col-sm-12 col-lg-4">
                            <h4>Godziny otwarcia</h4>
                            <table>
                                <thead></thead>
                                <tbody>
                                    <tr>
                                        <td>Pon - Pt</td>
                                        <td>8:00 - 21:00</td>
                                    </tr>
                                    <tr>
                                        <td>Sob</td>
                                        <td>8:00 - 16:00</td>
                                    </tr>
                                    {/* <tr>
                                        <td>Ndz</td>
                                        <td>Obowiązują zapisy</td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
           <script src='https://cdn1.pdmntn.com/a/BkFwFnZgh.js'></script> 
           </>
    )
}

export default Footer;