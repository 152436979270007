import './App.css';
import { React, useState, useEffect }from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Service from './components/Service';
import Contact from './components/Contact';
import Szkolenie from './components/Szkolenie';
import AutoZS from './components/AutoZS';
import Otomoto from './components/Otomoto';
import ReactPixel from 'react-facebook-pixel';
import Loyalty from './components/Loaylty';
import Urodziny from './components/Urodziny';
import Promocje from './components/Promocje';

const App = () =>{
  const advancedMatching = {};
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: true, // enable logs
  };
  ReactPixel.pageView();
  const [services, setServices] = useState();
  ReactPixel.init('302981035211011', advancedMatching, options);

  useEffect(()=>{
      fetch('https://test.cms.soft99.eu/garage-services')
        .then(resp => resp.json())
        .then((data) => {
          setServices(data);
        })
  },[])

  const szkolenia = ["main", "japonskie", "korekta", "indywidualne", "staz", "lsp", "pakiet"];
  const autozs = ["", "sprzedaz", "zakup"];

  const handleMapServices = () => {
    let temp = services;
      const mappedServices = temp.map((service)=>{
      
        return <Route key={service.id} path={"/"+service.garage_category.path +"/"+service.path} element={<Service service={service} services={services}/>}/>
    });
  return mappedServices;
  }

  const handleMapSzkolenia = () => {
    let tempArr = szkolenia;
      const mappedszkolenia = tempArr.map((szkol)=>{
      
        return <Route key={szkol.id} path={"/szkolenie/"+szkol} element={<Szkolenie active={szkol} services={services}/>}/>
    });
  return mappedszkolenia;
  }

  const handleMapAutoZS = () => {
    let tempArr = autozs;
    const mappedAutoZS = tempArr.map((autozsitem)=>{
      return <Route key={autozsitem.id} path={"/samochod/"+autozsitem} element={<AutoZS active={autozsitem} services={services}/>}/>
    });
  return mappedAutoZS;
  }

  return(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home services={services}/>} />
        <Route path="/promocje" element={<Promocje services={services}/>} />
        <Route path="/kontakt" element={<Contact services={services}/>} />
        <Route path="/szkolenie" element={<Szkolenie services={services} />} />
        <Route path="/program-lojalnosciowy" element={<Loyalty services={services} />} />
        <Route path="/urodziny" element={<Urodziny services={services} />} />
        <Route path="/samochod" element={<AutoZS active="" services={services} />} />
        <Route path="/sprzedaz" element={<Otomoto active="" services={services} />} />
        {szkolenia && handleMapSzkolenia()}
        {autozs && handleMapAutoZS()}
        {services && handleMapServices()}
      </Routes>
    </BrowserRouter>
  )
}

export default App;
