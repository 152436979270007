const addNewEvent = (mailName, mailPhone, start, end, allStations, selectedStation, description, bearer, setEventAdded)=>{

        fetch('https://cms.soft99.eu/calendar-vehicles', {
            method: "POST",
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                'Content-Type':'application/json',
                'Authorization': `Bearer ` + bearer, 
            },
            body: JSON.stringify(
                {
                    "brand": "Nieznany",
                    "model": "pojazd"
                }
            )
        })
            .then((response)=>response.json())
            .then(vehicle=>{
                let fetchedVehicle = vehicle
                    fetch('https://cms.soft99.eu/calendar-clients', {
                        method: "POST",
                        mode: 'cors',
                        headers: {
                            'Accept':'application/json',
                            'Content-Type':'application/json',
                            'Authorization': `Bearer ` + bearer, 
                        },
                        body: JSON.stringify({
                            "name": mailName,
                            "email": "nieznany@email.pl",
                            "phone": mailPhone,
                            "calendar_vehicles": fetchedVehicle.id
                        })
                    })
                    .then((response)=>response.json())
                    .then(client=>{
                                fetch('https://cms.soft99.eu/calendar-events', {
                                    method: "POST",
                                    mode: 'cors',
                                    headers: {
                                        'Accept':'application/json',
                                        'Content-Type':'application/json',
                                        'Authorization': `Bearer ` + bearer, 
                                    },
                                    body: JSON.stringify(
                                        {
                                        "title": client.name,
                                        "start": start,
                                        "end": end,
                                        "isDeleted": false,
                                        "calendar_client": client.id,
                                        "calendar_vehicle": fetchedVehicle.id,
                                        "calendar_station" : allStations.find(station=>station.id===parseInt(selectedStation)),
                                        "resources" : {
                                            "email": "",
                                            "description": description,
                                            "inspectionCard" : "",
                                            "rateDescription" : "",
                                            "rate" : 0
                                        }
                                        }
                                )}
                                ).then(response=>{
                                    if(response.ok){
                                        setEventAdded(true)
                                    }
                                })
                            })
                        })
}

export default addNewEvent
